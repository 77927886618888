<template>
  <v-container :grid-list-md="$vuetify.breakpoint.mdAndUp" :pa-0="$vuetify.breakpoint.smAndDown">
    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md9 xl6>
        <!-- <router-link to="/">
          <span>Active</span>
        </router-link> -->
        <!-- <v-btn text color="primary" to="/a/proposals">Active</v-btn>
        <v-btn text color="primary" to="/a/archived-proposals">Archived</v-btn> -->
        <v-sheet class="dropShadow">
          <v-flex xs12 py-3 px-4 class="primary">
          <span class="subheading font-weight-medium white--text">Submitted Proposals</span>
          </v-flex>
          <div v-if="isLoading" class="xs12 py-3 px-4">
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </div>
          <div v-else>
            <v-flex v-if="proposals.length > 0" xs12 pa-0>
              <div v-for="(proposal, index) in proposals" :key="proposal.index">
                <v-divider></v-divider>
                <v-container grid-list-md px-4 py-3>
                  <v-layout row wrap align-center>
                    <v-flex xs3>
                      <span class="caption">Submited {{ proposal.createdAt | fullDateToWords }}</span>
                    </v-flex>
                    <v-flex xs7>
                      <router-link :to="{ path: `/jobs/${proposal.problem._id}`}">
                      <span class="subheading font-weight-medium">{{ proposal.problem.title }}</span>
                      </router-link>
                    </v-flex>
                    <v-flex xs2>
                      <div class="right">
                        <UpdateProposal
                          :index="index"
                          :proposalId="proposal._id"
                          :message="proposal.message"
                          :amount="proposal.amount"
                          :proposals="proposals"
                          :followUps="proposal.followUps"
                        />
                        <v-tooltip bottom color="error">
                          <template v-slot:activator="{ on }">
                            <v-btn icon small color="error" v-on="on" :loading="isRetracting" @click="deleteProposal(proposal._id, index)"><v-icon small>delete</v-icon></v-btn>
                          </template>
                          <span>Retract Proposal</span>
                        </v-tooltip>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </div>
            </v-flex>
            <v-flex xs12 pa-0 v-else>
              <div class="text-xs-center py-3">
                <span class="display-1 grey--text">No Proposals</span>
              </div>
            </v-flex>
          </div>
        </v-sheet>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import toLocaleString from '@/mixins/toLocaleStringMixin'
import fullDateToWords from '@/mixins/momentFullDateToWordsMixin'
import UpdateProposal from '@/components/ProposalComponents/UpdateProposal'

export default {
  components: {
    UpdateProposal
  },
  mixins: [
    fullDateToWords,
    toLocaleString
  ],
  props: [
    'archived'
  ],
  data () {
    return {
      isRetracting: false
    }
  },
  methods: {
    deleteProposal (proposalId, index) {
      this.$swal.queue([{
        title: 'Are you sure?',
        text: "You will not revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          let content = { proposalId, index }
          return this.$store.dispatch('proposals/DELETE_USER_PROPOSAL', content)
            .then(() => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully retracted the selected proposal.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    }
  },
  created () {
    this.$store.dispatch('proposals/GET_USER_PROPOSALS', this.$store.getters['user/user'].user._id)
  },
  computed: {
    proposals () {
      return this.$store.getters['proposals/userProposals']
    },
    isLoading () {
      return this.$store.getters['proposals/isLoading']
    }
  }
}
</script>

<style scoped>
 a {
   text-decoration: none;
 }
</style>
